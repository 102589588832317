<template>
    <div class="reportWaiterNamePerf defaultReport">
        <div class="filter-box">
            <div class="filter-bnts">
                <div class="bnt verticalCenter" :class="{selected:queryType==false}" @click="nowadays()" >
                    <div class="title">
                        <span>今天<br/>({{userInfo.Rpt_Date}})</span>
                    </div>
                </div>
                <div class="bnt verticalCenter" :class="{selected:queryType==true}"  @click="queryType=true" v-if="ReportDataFromDays>=0">
                    <div class="title">
                        <span>按日期范围</span>
                    </div>
                </div>
                <div class="inline-block" v-if="ReportDataFromDays>=0">
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="daterange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="defaultTime"
                        @change="change"
                        :disabled='queryType==false'
                        :disabled-date="disabledDate"
                    > </el-date-picker>
                </div>
                <div class="bnt-search" @click="GetBillCheck()" >查询</div>
            </div>
            <div class="right-bnts select">
                <div class="bnt" @click="exportExcel()"><i class="iconfont icon-daochu"></i>导出报表</div>
            </div>
        </div>
        <div class="content-box clearfix">
            <div class="table-box grey-table headerCol2 isFooter" ref="tableBox"   >
                <el-table class="el-table--scrollable-y"  :data="tableListPage" 
                 border 
                 show-summary
                 :summary-method="getSummaries"
                @cell-dblclick="orderDetail"
                v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}" :default-sort = "{prop: 'date', order: 'descending'}">
                    <el-table-column fixed prop="Eat_WaiterName" label="服务员" min-width="100" >
                        <template #default="scope">
                            <span class="link">{{scope.row.Eat_WaiterName}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Eat_Number" label="销售数量" min-width="80" align="right"  :formatter="priceFormat"></el-table-column>
                    <el-table-column label="销售金额">
                        <el-table-column prop="Eat_SaleMoney" label="折前" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Eat_CheckMoney" label="折后" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Eat_FactMoney" label="实收" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="NoTaxFactMoney" label="去税" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    </el-table-column>
                    <el-table-column prop="Eat_CostMoney" label="成本金额" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column label="毛利率%" min-width="240">
                        <el-table-column prop="Rate_SaleMoney" label="折前%" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Rate_CheckMoney" label="折后%" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Rate_FactMoney" label="实收%" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                        <el-table-column prop="Rate_NoTaxFactMoney" label="去税%" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    </el-table-column>
                    <el-table-column prop="Eat_ManNum" label="人数" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Avg_ManNum" label="人均" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Eat_DeskNum" label="桌数" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                    <el-table-column prop="Avg_DeskNum" label="桌均" min-width="80" align="right" :formatter="priceFormat"></el-table-column>
                </el-table>
            </div>
            <table-page-btn class="fy" :data="tableList" @page-change="(list)=>tableListPage=list" :page-size="pagesize"></table-page-btn>
        </div>
     </div>
</template>
<script>

import FileSaver from 'file-saver'
import XLSX from 'xlsx'

/**服务员业绩统计表 */
export default {
    name:'reportWaiterNamePerf',
    data(){
        return {
            userInfo:'',
            /**表格1数据 */ 
            currentPage:1,
            tableList:[],
            tableListPage:[],
            pagesize:9,//每页的数据条数
            
            /**默认日期范围 */
            defaultTime:[new Date(),new Date()],
            /**选中日期范围 */
            businessHours:[new Date(),new Date()],
            /**true: 日期范围    false：当前营业数据*/
            queryType:false,
        }
    },
    mounted(){
        this.userInfo = this.$auth.getUserInfo();
        this.nowadays();
        
    },
    computed:{
        //限制门店用户查看几天以内的报表, -1:不能查看历史数据，0:不限制，大于0：限制查看几天内的报表
        ReportDataFromDays(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserViewReportDataFromDays||0
        },
        //合计
        total(){
            let total={
                Eat_Number:0,
                Eat_SaleMoney:0,
                Eat_CheckMoney:0,
                Eat_FactMoney:0,
                NoTaxFactMoney:0,
                Eat_CostMoney:0,
                Eat_ManNum:0,
                Eat_DeskNum:0,
            };
            (this.tableList||[]).forEach(it=>{
                total.Eat_Number+=it.Eat_Number;
                total.Eat_SaleMoney+=it.Eat_SaleMoney;
                total.Eat_CheckMoney+=it.Eat_CheckMoney;
                total.Eat_FactMoney+=it.Eat_FactMoney;
                total.NoTaxFactMoney+=it.NoTaxFactMoney;
                total.Eat_CostMoney+=it.Eat_CostMoney;
                total.Eat_ManNum+=it.Eat_ManNum;
                total.Eat_DeskNum+=it.Eat_DeskNum
            })
            total.Rate_SaleMoney=total.Eat_SaleMoney==0?0:(total.Eat_SaleMoney-total.Eat_CostMoney)/total.Eat_SaleMoney*100;
            total.Rate_CheckMoney=total.Eat_CheckMoney==0?0:(total.Eat_CheckMoney-total.Eat_CostMoney)/total.Eat_CheckMoney*100;
            total.Rate_FactMoney=total.Eat_FactMoney==0?0:(total.Eat_FactMoney-total.Eat_CostMoney)/total.Eat_FactMoney*100;
            total.Rate_NoTaxFactMoney=total.NoTaxFactMoney==0?0:(total.NoTaxFactMoney-total.Eat_CostMoney)/total.NoTaxFactMoney*100;

            total.Avg_ManNum=total.Eat_ManNum==0?0:total.Eat_SaleMoney/total.Eat_ManNum;
            total.Avg_DeskNum=total.Eat_DeskNum==0?0:total.Eat_SaleMoney/total.Eat_DeskNum;
            return total;
        }
    },
    watch:{
    },
    methods:{
        disabledDate(d){
            if(this.ReportDataFromDays>0 && this.userInfo.Rpt_Date){
                const oneDay =  24 * 3600 * 1000;
                const day = this.ReportDataFromDays * oneDay;
                let maxDate=new Date(this.userInfo.Rpt_Date).getTime()-oneDay;
                let minDate=maxDate-day;
                if(minDate>=d.getTime() || maxDate<d.getTime()){
                    return true;
                }
            }
            return false;
        },
        /**计算 一页显示多少条数据 */
        setTablePageSize(){
            if(this.$refs.tableBox){
                this.$nextTick(()=>{
                    let height=this.$refs.tableBox.clientHeight - 80;
                    this.pagesize=parseInt(height/50);
                })
            }
        },
        /**Excel导出 */
        exportExcel(){
            if(!this.$cacheData?.globalVariable?.GlobalSysSetting.AllowUserPrintReports){
                this.$message.warning('您没有导出报表权限，请先分配权限！');
                return;
            }
            let _pagesize = this.pagesize
            this.pagesize = this.tableList.length;//表格长度变长
            this.currentPage= 1;
            this.$refs.tableBox.style.display="none";
            this.$nextTick(function () {
                let table = this.$refs.tableBox.cloneNode(true);
                table.querySelector(".el-table__fixed")?.remove();
                table.querySelectorAll(".el-table__footer td")?.forEach(it=>{
                    it.setAttribute("rowspan","1");
                })
                let xlsxParam = { raw: true };
                let wb = XLSX.utils.table_to_book(table,xlsxParam);
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                try {
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), '服务员业绩统计表.xlsx')
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                this.pagesize = _pagesize;//表格还原
                this.$nextTick(()=>{
                    this.$refs.tableBox.style.display='';
                })
                return wbout
            })
        },
        /**今日 */
        nowadays(){
            this.queryType=false;
            let Rpt_Date=new Date(this.userInfo.Rpt_Date);
            Rpt_Date.setDate(Rpt_Date.getDate()-1);
            this.businessHours=[Rpt_Date.Format("yyyy-MM-dd"),Rpt_Date.Format("yyyy-MM-dd")];
        },
        change(){
            if(this.queryType==false){
                this.queryType=true
            }
        },
         /*单价格式 截取两位小数 */
        priceFormat(row, column, cellValue){
            if(isNaN(cellValue)){
                return ""
            }else{
                return parseFloat(parseFloat(cellValue).toFixed(2));
            }
        },
        getSummaries({ columns, data }){
            const sums= [];
            columns.forEach((column, index) => {
                if(this.total[column.property]!=undefined){
                    sums[index]=this.priceFormat(null,null,this.total[column.property]);
                }else{
                    sums[index]="";
                }
            })
            sums[0]="合计";
            return sums;
        },
        /**查询 */
        GetBillCheck(){
            let time = JSON.parse(JSON.stringify(this.businessHours));
            let param={
                User_ID:this.userInfo?.User_ID,
                Operator_Name:this.userInfo?.Login_Name,
                DateRange_YN:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                Begin_Date:new Date(time[0]).Format("yyyy-MM-dd"),//开始日期
                End_Date:new Date(time[1]).Format("yyyy-MM-dd"), //结束日期
            }
            const loading = this.$loading({
                text: "查询中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });

            this.$httpAES.post("Bestech.CloudPos.WaiterPerfReport",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tableList=d.ResponseBody
                    this.setTablePageSize()
                    this.currentPage=1
                }else{
                    this.tableList=[];
                    this.currentPage=1
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                this.tableList=[]
                loading.close();
                this.$message.error(e.message);
                console.log(e);
            })
        },
        //穿透到 明细报表
        orderDetail(row,column){
            if(column.property=="Eat_WaiterName"){//穿透 服务员账单统计表
                this.$emit("addFrame",{
                    key:"waiter"+row.Eat_WaiterName,
                    data:{
                        Eat_WaiterName:row.Eat_WaiterName,
                        queryType:this.queryType,//是否日期范围查询  true: 日期范围    false：当前营业数据
                        businessHours:this.businessHours,//开始日期 结束日期
                    },
                    title:'服务员账单统计表',
                    url:'reportWaiterNameBill'
                });
            }
        },
    }
}
</script>

<style lang="scss" >
@import './reportWaiterNamePerf.scss'
</style>